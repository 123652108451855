$(document).ready(function () {

    ClassicEditor
        .create(document.querySelector('.step_editor'), {
            height: 400
        })
        .catch(error => {
            // console.error( error );
        });

    FilePond.registerPlugin(
        FilePondPluginFileEncode,
        FilePondPluginFileValidateSize,
        FilePondPluginImageExifOrientation,
        FilePondPluginImagePreview
    );

    FilePond.create(
        document.querySelector('.step_inputFile'),
        {
            labelIdle: `<span class="filepond--label-action">Ajoute des photos</span>`,
        }
    );

    FilePond.create(
        document.querySelector('.step_inputProfil'),
        {
            labelIdle: `<span class="filepond--label-action">Ajouter</span>`,
            imagePreviewHeight: 200,
            imageCropAspectRatio: '1:1',
            imageResizeTargetWidth: 200,
            imageResizeTargetHeight: 200,
            stylePanelLayout: 'compact circle',
            styleLoadIndicatorPosition: 'center bottom',
            styleButtonRemoveItemPosition: 'center bottom'
        }
    );

    $('.step_inputTags').select2({
        placeholder: 'Ajouter',
        multiple: true,
        tags: true,
    });

    var $owl = $(".owl-carousel");

    $owl.owlCarousel({
        loop: false,
        margin: 0,
        items: 1,
        nav: false,
        lazyLoad: true,
        dots: false
    });

    $('.step_next').on('click', () => {
        $owl.trigger('next.owl.carousel');
    });

    $('.footer_dot-1').on('click', () => {
        $owl.trigger('to.owl.carousel', 0);
    });

    $('.footer_dot-2').on('click', () => {
        $owl.trigger('to.owl.carousel', 1);
    });

    $('.footer_dot-3').on('click', () => {
        $owl.trigger('to.owl.carousel', 2);
    });

    $('.footer_dot-4').on('click', () => {
        $owl.trigger('to.owl.carousel', 3);
    });

    $('.footer_dot-5').on('click', () => {
        $owl.trigger('to.owl.carousel', 4);
    });

    $owl.on('changed.owl.carousel', function(e) {
        for(let i = 1; i <= 5; i++) {
            $('.footer_dot-' + i).removeClass('footer_dot-active');
        }
        for(let i = 1; i <= e.item.index  + 1; i++) {
            $('.footer_dot-' + i).addClass('footer_dot-active');
        }
    });


    $('.footer_post').on('click', function (e) {
        $('.step_inputText')

        var url = '';
        var data = [];

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: function () {
                console.log('ok')
            }
        });
    });
});

